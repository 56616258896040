<template>
    <admin-dashboard-layout>
        <v-data-table
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="users"
            :items-per-page="quantityItems"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:item.balance="{ item }">
                {{ item.balance }}{{ currency }}
            </template>
        </v-data-table>

        <div class="text-right">
            Total: {{ totalWalletBalance }}{{ currency }}
        </div>
    </admin-dashboard-layout>
</template>

<script>
import User from "../../../models/admin/User";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import {
    ADMIN_QUANTITY_ITEMS_LIST,
    CURRENCY_SYMBOL,
} from "../../../configs/constants";
import { mapMutations } from "vuex";
/* eslint-disable */
export default {
    name: "users-index",
    components: { AdminDashboardLayout },
    data: function () {
        return {
            loading: false,
            quantityItems: ADMIN_QUANTITY_ITEMS_LIST,
            users: [],
            search: "",
            currency: CURRENCY_SYMBOL,
            headers: [
                {
                    text: "# ID",
                    align: "start",
                    sortable: false,
                    value: "id",
                },
                { text: "Name", value: "name" },
                { text: "Wallet Balance", value: "balance" },
            ],
        };
    },
    methods: {
        handleClickRow(user) {
            this.$router.push({
                name: "admin.users.show",
                params: { id: user.id },
            });
        },
        handlePusherListener() {
            this.$echo.connector.connect();
            this.$echo
                .private("user-registered")
                .listen("UserSignUpEvent", async (data) => {
                    if (typeof data.message !== "undefined") {
                        this.showSnackBar(data.message);
                        this.users = await User.get();
                    }
                })
                .error((err) => {
                    console.error(err);
                });
        },
        ...mapMutations(["showSnackBar"]),
    },
    computed: {
        refreshTime() {
            const minute = 60000;
            if (process.env.VUE_APP_LAST_ONLINE_REFRESH_MINUTES) {
                return process.env.VUE_APP_LAST_ONLINE_REFRESH_MINUTES * minute;
            }
            return 10 * minute;
        },
        totalWalletBalance() {
            return this.users.reduce((total, user) => total + user.balance, 0);
        },
    },
    async mounted() {
        this.loading = true;
        this.users = await User.get();
        this.loading = false;
        setInterval(async () => {
            this.users = await User.get();
        }, this.refreshTime);
        this.handlePusherListener();
    },
};
</script>

<style scoped></style>
